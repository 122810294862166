import $ from 'jquery'

$(window).on('load scroll', function(){
  if ($(window).scrollTop() > 200) {
    $('#scroll-show-button').fadeIn(400)
   } else {
    $('#scroll-show-button').fadeOut(400)
   }
})

